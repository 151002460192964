import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const HeaderStyles = styled.header`
  nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, auto);
    /* grid-template-columns: auto auto auto auto; shorthand */
    justify-content: space-between;
    @media (min-width: 768px) {
      grid-template-columns: 1fr auto auto auto;
    }
  }
  nav ul li a {
    display: inline-block;
    padding: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.color.text};
    font-size: 2rem;
  }

  .active {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    text-decoration-color: ${({ theme }) => theme.color.primary};
  }
`;

export default function Header() {
  return (
    <HeaderStyles>
      <nav>
        <ul>
          <li>
            <Link to={"/"}>Aria Andersson</Link>
          </li>
          <li>
            <Link to={"/rates"} activeClassName={`active`}>
              Rates
            </Link>
          </li>
          <li>
            <Link to={"/contact"} activeClassName={`active`}>
              Contact
            </Link>
          </li>
          {/* <li>
                    <Link to={'/gallery'}>Gallery</Link>
                </li> */}
        </ul>
      </nav>
    </HeaderStyles>
  );
}
