import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import "../styles/normalize.css";

const mainTheme = {
  color: {
    text: "#212427",
    contrastText: "#fdfdfd",
    background: "#fdfdfd",
    primary: "#0E2E19",
    secondary: "#fdfdfd",
    linkText: "#F9DC5C",
    // linkText: "#F0BCBF",
    // linkText: "#E0777D", // Pink
  },
  fonts: {
    header: "Prata",
    copy: "Jost",
    // copy: "Montserrat",
  },
};

const GlobalStyles = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.color.background};
        color: ${({ theme }) => theme.color.text};
        font-family: ${({ theme }) => theme.fonts.copy}, sans-serif;
    }

    h1, h2 {
        font-family: ${({ theme }) => theme.fonts.header}, serif;
        /* line-height: 1.5; */
        /* letter-spacing: 0.01rem; */
        /* letter-spacing: .015em; */
    }

    p {
        line-height: 1.4;
    }
`;

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
