import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const FooterStyles = styled.footer`
  text-align: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.secondary};

  .links {
    text-align: center;
    padding: 0 0 0.5rem 0;
    a {
      color: ${({ theme }) => theme.color.secondary};
      /* text-decoration: none; */
      text-underline-offset: 0.25rem;
      padding: 0 0.25rem;
    }
  }
  .socials__links {
    padding: 0.5rem;
    svg {
      color: ${({ theme }) => theme.color.secondary};
      height: 25px;
      width: 25px;
      padding: 0 0.25rem;
    }
  }

  .dot {
    height: 0.3rem;
    width: 0.3rem;
    background: ${({ theme }) => theme.color.secondary};
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.1rem;
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <p>
        I'm excited to meet you! ♡ Aria Andersson {new Date().getFullYear()}
      </p>
      <div className="links">
        <Link to="/contact">Contact</Link>
        <Link to="/rates">Rates</Link>
      </div>
      {/* <span className="dot" /> */}
      <div className="socials__links">
        <a href="https://twitter.com/AriaAndersson">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://ariaandersson.tryst.link/">
          <FontAwesomeIcon icon={faLink} />
        </a>
      </div>

      {/*     
    Contact
    
    Rates
    
    Gallery */}
      {/* Social links */}
    </FooterStyles>
  );
}
